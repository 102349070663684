import { Translation } from 'shared/components/translation/translation.component';

import './pill.component.scss';

interface Props {
  variant?: 'default' | 'error' | 'warning';
  resource: ResourceKey;
}

export const Pill = ({ variant, resource }: Props) => {
  return <div className={`${variant || 'default'} pill`}>
    {(variant === 'error' || variant === 'warning') ?  <span className="glyphicon glyphicon-warning-sign"/> : null}
    <span><Translation resource={resource}/></span>
  </div>;
};
